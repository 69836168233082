
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import DateUtils from "@/services/utils/DateUtils";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { IExternalRoute } from "@/models/IRoute";
import {
  PROPERTY_ACTIVE_PROCESS_CARD_TYPE,
  PROPERTY_CARD_SUBTITLE_TYPE,
  PROPERTY_CARD_BADGE_TYPE,
} from "@/constants/PropertyCardType";
import {
  IPropertyActiveProcessStatus,
  IPropertyStatusSubtitle,
  IPropertyStatusBadge,
  IPropertyStatusInfoTexts,
} from "@/models/Status";
import {
  PropertyActiveProcessCardType,
  PropertyCardBadgeType,
} from "@/enums/PropertyCardType";
import { IPropertyInfo } from "@/models/PropertyInfo";
import { IRentalLeasings } from "@/models/RentalLeasing";
import Money from "@/models/Money";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import { RentalMandatePacks } from "@/constants/RentalMandatePacks";

@Component({
  name: "PropertyActiveProcessCard",
  components: {
    HousfySvg,
    HousfyLink,
  },
})
export default class PropertyActiveProcessCard extends Vue {
  @Prop({ type: String, required: true })
  cardType!: PropertyActiveProcessCardType;
  @Prop({ type: Object, default: () => {} })
  info!: IPropertyInfo;
  @Prop({ type: Object, default: () => {} })
  leasings!: IRentalLeasings;
  @Prop({ type: String, required: true })
  dataQa!: string;

  get statusData(): IPropertyActiveProcessStatus {
    const statusCard =
      PROPERTY_ACTIVE_PROCESS_CARD_TYPE[
        this.cardType as keyof typeof PROPERTY_ACTIVE_PROCESS_CARD_TYPE
      ];
    if (statusCard?.infoText?.plan) {
      const planKeyName =
        RentalMandatePacks[
          this.info.rentalMandatePack as keyof typeof RentalMandatePacks
        ];
      statusCard.infoText.plan.value = this.$te(planKeyName)
        ? this.$t(planKeyName)
        : "common.other";
    }

    if (statusCard.needsDateInfo) {
      let subtitleDate;
      if (
        this.cardType ===
        PropertyActiveProcessCardType.PENDING_RECOVER_FUTURE_DATE
      ) {
        subtitleDate = this.info.rentalPublishingDate;
      }

      if (subtitleDate) {
        const { day, month, year } = DateUtils.formatDate(subtitleDate);
        const monthString = this.$t("common." + month + "Short")
          .toString()
          .toLowerCase();
        const formattedDate = day + " " + monthString + " " + year;

        statusCard.subtitle = statusCard.subtitle
          ? this.$t(statusCard.subtitle, {
              date: formattedDate,
            }).toString()
          : formattedDate;
      }
    }

    return statusCard;
  }

  get cardStyle(): string {
    if (this.statusData.clickable)
      return "bg-white p-16 shadow-lg hover:shadow-none border-sm border-white hover:border-sm hover:border-gray30 items-center justify-between";
    else if (
      this.cardType === PropertyActiveProcessCardType.RESIGNATION ||
      this.cardType === PropertyActiveProcessCardType.PENDING_SIGNATURE ||
      this.cardType === PropertyActiveProcessCardType.PENDING_RECOVER ||
      this.cardType ===
        PropertyActiveProcessCardType.PENDING_RECOVER_FUTURE_DATE
    )
      return "bg-transparent p-16 items-center justify-between border-sm border-gray30";
    else if (this.cardType === PropertyActiveProcessCardType.CANCELLED)
      return "bg-transparent gray30 items-center justify-between";
    else if (
      this.cardType === PropertyActiveProcessCardType.CAPTURED_NOT_CLICKABLE
    )
      return "bg-white p-16 shadow-lg border-sm border-white  items-center justify-between";

    return "bg-transparent gray30 items-start justify-between";
  }

  get subtitleStyle(): IPropertyStatusSubtitle {
    const subtitleType = this.statusData.subtitleType;
    return subtitleType
      ? PROPERTY_CARD_SUBTITLE_TYPE[subtitleType]
      : {
          icon: "",
          class: "text-gray70",
          iconColor: "",
        };
  }

  get badgeStyle(): IPropertyStatusBadge {
    const badgeType = this.statusData.badgeType;
    return badgeType
      ? PROPERTY_CARD_BADGE_TYPE[badgeType]
      : PROPERTY_CARD_BADGE_TYPE[PropertyCardBadgeType.DEFAULT];
  }

  get badgeType() {
    return PropertyCardBadgeType;
  }

  get infoTextDate(): string | null {
    if (!this.statusData.infoText) return null;

    let fromDate: string | null;

    fromDate = this.info.rentalMandateSignatureDate || "";
    if (this.cardType === PropertyActiveProcessCardType.IN_MANAGEMENT) {
      const wonOrOngoingLeasing = this.leasings?.items?.find(
        (leasing) =>
          leasing.status === RentalLeasingStatus.ONGOING ||
          leasing.status === RentalLeasingStatus.WON
      );
      fromDate = wonOrOngoingLeasing?.leasingContractValidityDate || null;
    } else if (this.cardType === PropertyActiveProcessCardType.NOT_RENTED) {
      if (this.leasings?.items?.length) {
        const orderedLeasingsByTerminationDate = this.leasings.items.sort(
          (a, b) => {
            const dateA = a?.leasingTerminationDate
              ? new Date(a?.leasingTerminationDate).getTime()
              : 0;
            const dateB = b?.leasingTerminationDate
              ? new Date(b?.leasingTerminationDate).getTime()
              : 0;
            return dateB - dateA;
          }
        );
        fromDate =
          orderedLeasingsByTerminationDate[0].leasingTerminationDate || null;
      } else fromDate = null;
    }

    if (fromDate) {
      const { day, month, year } = DateUtils.formatDate(fromDate);
      return day + " " + this.$t("common." + month + "Short") + " " + year;
    }
    return null;
  }

  get infoTexts(): IPropertyStatusInfoTexts | null {
    if (!this.statusData.infoText) return null;

    const formattedDate = this.infoTextDate;
    const infoTexts: IPropertyStatusInfoTexts = this.statusData.infoText;

    if (formattedDate && infoTexts.date) infoTexts.date.value = formattedDate;
    const wonOrOngoingLeasing = this.leasings?.items?.find(
      (leasing) =>
        leasing.status === RentalLeasingStatus.ONGOING ||
        leasing.status === RentalLeasingStatus.WON
    );

    if (infoTexts.tenantNames)
      infoTexts.tenantNames.value =
        wonOrOngoingLeasing?.tenantNames?.join(", ") || "";
    if (wonOrOngoingLeasing?.monthlyPrice && infoTexts.price) {
      infoTexts.price.value = new Money(
        Number(wonOrOngoingLeasing.monthlyPrice) / 100
      )
        .withOptions({
          withCents: true,
        })
        .format(true);
    }
    return infoTexts;
  }

  get containerComponent(): string {
    return this.statusData.clickable ? "HousfyLink" : "div";
  }

  get propertyLink(): IExternalRoute {
    if (this.cardType === PropertyActiveProcessCardType.CAPTURED)
      return MYHExternalRoutes.dashboardPropertyCreation(
        this.info.propertyUuid,
        this.info.hasAppointmentManager
      );
    return MYHExternalRoutes.dashboardPropertyPublished(
      this.info.propertyUuid,
      this.info.hasAppointmentManager
    );
  }
}
